<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Domain Infrastruktur"
          label-for="vi-inf_domain_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Domain Infrastruktur"
            rules="required"
          >
            <v-select
              id="vi-inf_domain_id"
              v-model="inf_domain_id"
              required
              name="inf_domain_id"
              :options="referenceData.ref_domain_infra"
              placeholder="Domain Infrastruktur"
              label="nama"
              @input="getAreaInfra()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Area Infrastruktur"
          label-for="vi-inf_area_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Area Infrastruktur"
            rules="required"
          >
            <v-select
              id="vi-inf_area_id"
              v-model="inf_area_id"
              required
              name="inf_area_id"
              :options="daftarAreaInfra"
              placeholder="Area Infra"
              label="nama"
              @input="getKategoriInfra()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Kategori Infrastruktur"
          label-for="vi-inf_category_id"
        >
          <v-select
            id="vi-inf_category_id"
            v-model="inf_category_id"
            name="inf_category_id"
            :options="daftarKategoriInfra"
            :disabled="(daftarKategoriInfra.length === 0) ? true : false"
            placeholder="Kategori Infrastruktur"
            label="nama"
            @input="SetNamaKategoriInfra()"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="`Nama ${inf_domain_id.name} yang digunakan.`"
          :label="`Nama ${nama_infra}`"
          label-for="vi-nama"
        >
          <validation-provider
            #default="{ errors }"
            :name="`Nama ${nama_infra}`"
            rules="required"
          >
            <b-form-input
              id="vi-nama"
              v-model="nama"
              required
              name="nama"
              :placeholder="`Nama ${nama_infra}`"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan status kegiatan/pengadaan Infra.'"
          label="Status Kegiatan"
          label-for="vi-inf_status_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Status kegiatan"
            rules="required"
          >
            <v-select
              id="vi-inf_status_id"
              v-model="inf_status_id"
              required
              name="inf_status_id"
              :options="referenceData.ref_status_kegiatan"
              placeholder="Status Kegiatan"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>

    <template v-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 1">
      <!-- Type Sistem Integrasi : Jaringan Intra Pemerintah -->
      <infra-sis-jar
        ref="sisJar"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template v-else-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 7">
      <!-- Type Sistem Integrasi : Sistem Penghubung Layanan Pemerintah -->
      <infra-sis-hub
        ref="sisJar"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 3">
      <!-- Type Platform : Komputasi Awan -->
      <infra-plat-cloud
        ref="platCloud"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 8"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Software -->
      <infra-plat-kia-soft
        ref="platKiaSoft"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 7"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Server -->
      <infra-plat-kia-server
        ref="platKiaServer"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 5"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Media Penyimpanan -->
      <infra-plat-kia-storage
        ref="platKiaStorage"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 3"
    >
      <infra-plat-kia-network
        ref="platKiaNetwork"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 4"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Keamanan -->
      <infra-plat-kia-security
        ref="platKiaSecurity"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <template
      v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 6"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Periferal -->
      <infra-plat-kia-periferal
        ref="platKiaPeriferal"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <!-- Type Fasilitas Komputasi : ALL -->
    <template v-else>
      <infra-fasil
        ref="fasil"
        :inf-data="inf_data"
        :action-step="reqData"
        :data-referensi="referenceData"
      />
    </template>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>

      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(total_anggaran !== '') ? ': Rp. ' + Number(total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="total_anggaran"
              type="number"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="kak_uri !== null"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>{{ kak_uri.substring(kak_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
        </p>
      </b-col>
      <b-col
        v-if="additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in additional_docs"
          :id="`dt-${data.document_id}`"
          :key="index"
        >{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <b-button
            variant="outline-danger"
            size="sm"
            class="mr-25 mb-25"
            @click="removeFile(data.document_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            /></b-button>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf)'"
          label="Kerangka Acuan Kerja (dalam format PDF)"
          label-for="file_kak"
        >
          <validation-provider
            #default="{ errors }"
            name="File KAK"
            rules="required"
          >
            <b-form-file
              ref="file_kak"
              v-model="file1"
              name="file_kak"
              class="mt-1"
              required
              @input="checkFile"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Tambahan (dalam format .pdf)'"
          label="Dokumen Tambahan (dalam format PDF)"
          label-for="file_extra_doc"
        >
          <b-form-file
            ref="file_extra_doc"
            v-model="file2"
            name="file_extra_doc"
            class="mt-1"
            multiple
            @input="checkFile"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Dokumen Tambahan?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BButton, BFormFile, BFormInput, BRow, BCol,
  BModal, BFormGroup, VBTooltip, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

import InfraFasil from '../template-infra/InfraFasil.vue'
import InfraSisJar from '../template-infra/InfraSisJar.vue'
import InfraSisHub from '../template-infra/InfraSisHub.vue'
import InfraPlatCloud from '../template-infra/InfraPlatCloud.vue'
import InfraPlatKiaSoft from '../template-infra/InfraPlatKiaSoft.vue'
import InfraPlatKiaServer from '../template-infra/InfraPlatKiaServer.vue'
import InfraPlatKiaStorage from '../template-infra/InfraPlatKiaStorage.vue'
import InfraPlatKiaNetwork from '../template-infra/InfraPlatKiaNetwork.vue'
import InfraPlatKiaSecurity from '../template-infra/InfraPlatKiaSecurity.vue'
import InfraPlatKiaPeriferal from '../template-infra/InfraPlatKiaPeriferal.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,

    InfraFasil,
    InfraSisJar,
    InfraSisHub,
    InfraPlatCloud,
    InfraPlatKiaSoft,
    InfraPlatKiaServer,
    InfraPlatKiaStorage,
    InfraPlatKiaNetwork,
    InfraPlatKiaSecurity,
    InfraPlatKiaPeriferal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      default: () => { },
    },
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        ref_domain_infra: [],
        ref_area_infra: [],
        ref_kategori_infra: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
        ref_status_kegiatan: [],
        ref_kepemilikan: [],
        ref_teknik_penyimpanan: [],
        ref_data_tematik: [],
        ref_jenis_lisensi: [],
        ref_jenis_prosesor: [],
      },
      nama_infra: 'Pusat Data',
      data_utama_id: '',
      errorMsg: '',
      infrastruktur_id: '',
      dataSaved: false,
      reqData: 0,
      inf_data: {},
      inf_domain_id: {
        domain_infra_id: 1,
        nama: 'Fasilitas Komputasi',
      },
      inf_area_id: {
        area_infra_id: 4,
        nama: 'Pusat Data',
      },
      inf_category_id: null,
      nama: '',
      inf_status_id: '',
      total_anggaran: '',
      jenis_belanja: '',
      jenis_pengadaan: '',
      sumber_dana: '',
      file1: null,
      file2: [],
      daftarAreaInfra: [],
      daftarKategoriInfra: [],
      kak_uri: null,
      additional_docs: [],
      fileDoc: '',
      toDelData: '',
    }
  },
  watch: {
    clearanceId() {
      this.setClearanceId()
    },
    actionStep() {
      this.simpanInfra()
    },
  },
  mounted() {
    this.getReference()
    // this.infrastruktur_id = 924
  },
  methods: {
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else if (input.type !== 'application/pdf') {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Jenis Dokumen Tidak Sesuai`,
        }
      } else {
        this.alert = false
      }
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    removeFile(value) {
      this.toDelData = value
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
    },
    deleteItem() {
      this.$http.post('/clearance/infrastruktur/file/delete', {
        document_id: this.toDelData,
        infrastruktur_id: this.infrastruktur_id,
      }, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById(`dt-${this.toDelData}`).remove()
            this.clearDeleteItem()
          }
        })
    },
    getReference() {
      if (this.clearanceId) {
        this.$http.get('/clearance/infrastruktur/references', {
          params: {
            token: localStorage.getItem('userToken'),
            data_utama_id: this.clearanceId,
            tahun_anggaran: this.coreData.year_budget,
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.referenceData = res.data.data

              const options = this.referenceData.default_options
              this.referenceData.ref_data_tematik.map(contact => {
                if (contact.data_tematik_id === options.ref_data_tematik) {
                  this.inf_data.data_tematik_id = contact
                }
                return true
              })
              this.referenceData.ref_jenis_belanja.map(contact => {
                if (contact.jenis_belanja_id === options.ref_jenis_belanja) {
                  this.jenis_belanja = contact
                }
                return true
              })
              this.referenceData.ref_jenis_pengadaan.map(contact => {
                if (contact.jenis_pengadaan_id === options.ref_jenis_pengadaan) {
                  this.jenis_pengadaan = contact
                }
                return true
              })
              this.referenceData.ref_sumber_dana.map(contact => {
                if (contact.sumber_dana_id === options.ref_sumber_dana) {
                  this.sumber_dana = contact
                }
                return true
              })
              this.getAreaInfra()
              this.inf_area_id = {
                area_infra_id: 4,
                nama: 'Pusat Data',
              }
            }
          })
      }

      return true
    },
    setClearanceId() {
      this.data_utama_id = this.clearanceId
      this.getReference()
    },
    getAreaInfra() {
      this.daftarAreaInfra.splice(0)
      this.inf_area_id = ''
      this.inf_category_id = null

      const filterVal = this.inf_domain_id.domain_infra_id
      const refData = this.referenceData.ref_area_infra
      refData.map(contact => {
        if (contact.domain_infra_id === filterVal) {
          this.daftarAreaInfra.push(contact)
        }
        return true
      })
    },
    getKategoriInfra() {
      this.daftarKategoriInfra.splice(0)
      this.inf_category_id = ''

      const filterVal = this.inf_area_id.area_infra_id
      this.nama_infra = this.inf_area_id.nama

      const refData = this.referenceData.ref_kategori_infra
      refData.map(contact => {
        if (contact.area_infra_id === filterVal) {
          this.daftarKategoriInfra.push(contact)
        }
        return true
      })
    },
    SetNamaKategoriInfra() {
      this.nama_infra = this.inf_category_id.nama
    },
    simpanInfra() {
      if (!this.kak_uri && !this.file1) {
        const data = {
          status: false,
          msg: 'File KAK Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        this.saveInfra()
      }
    },
    saveInfra() {
      this.errorMsg = ''
      const metaUtama = {
        instansi_id: this.userData.instansi_id,
        data_utama_id: this.clearanceId,
        domain_infra_id: this.inf_domain_id.domain_infra_id,
        area_infra_id: this.inf_area_id.area_infra_id,
        nama: this.nama,
        status_kegiatan_id: this.inf_status_id.status_kegiatan_id,
        total_anggaran: Number(this.total_anggaran),
        sumber_dana_id: this.sumber_dana.sumber_dana_id,
        jenis_belanja_id: this.jenis_belanja.jenis_belanja_id,
        jenis_pengadaan_id: this.jenis_pengadaan.jenis_pengadaan_id,
        bandwidth: this.inf_data.bandwidth,
        lokasi: this.inf_data.lokasi,
        unit_kerja: this.inf_data.unit_kerja,
        tier: this.inf_data.tier,
        sistem_pengaman: this.inf_data.sistem_pengaman,
        jenis: this.inf_data.jenis,
        nama_pemilik: this.inf_data.nama_pemilik,
        tipe_media_jaringan: this.inf_data.tipe_media_jaringan,
        media_lainnya: this.inf_data.media_lainnya,
        deskripsi: this.inf_data.deskripsi,
        nama_jaringan: this.inf_data.nama_jaringan,
        aplikasi_terhubung: this.inf_data.aplikasi_terhubung,
        biaya_layanan: this.inf_data.biaya_layanan,
        unit_pengembang: this.inf_data.unit_pengembang,
        unit_operasional: this.inf_data.unit_operasional,
        jangka_waktu_layanan: this.inf_data.jangka_waktu_layanan,
        perangkat_lunak: this.inf_data.perangkat_lunak,
        kapasitas_memori: this.inf_data.kapasitas_memori,
        kapasitas_penyimpanan: this.inf_data.kapasitas_penyimpanan,
        pemilik_lisensi: this.inf_data.pemilik_lisensi,
        validasi_lisensi: this.inf_data.validasi_lisensi,
        metode_akses_penyimpanan: this.inf_data.metode_akses_penyimpanan,
        // software_type: this.inf_data.software_type,
        // used_data_id: this.inf_data.used_data_id,
        // inf_nama_jaringan: this.inf_data.inf_nama_jaringan,
      }
      if (this.infrastruktur_id) {
        metaUtama.infrastruktur_id = this.infrastruktur_id
      }
      if (this.inf_category_id !== null) {
        metaUtama.kategori_infra_id = this.inf_category_id.kategori_infra_id
      }
      if (this.inf_data.kepemilikan) {
        metaUtama.kepemilikan_id = this.inf_data.kepemilikan.kepemilikan_id
      }
      if (this.inf_data.data_tematik_id) {
        metaUtama.data_tematik_id = this.inf_data.data_tematik_id.data_tematik_id
      }
      if (this.inf_data.processor_type) {
        metaUtama.jenis_prosesor_id = this.inf_data.processor_type.jenis_prosesor_id
      }
      if (this.inf_data.storage_technic) {
        metaUtama.teknis_penyimpanan_id = this.inf_data.storage_technic.teknis_penyimpanan_id
      }
      if (this.inf_data.license_type) {
        metaUtama.jenis_lisensi_id = this.inf_data.license_type.jenis_lisensi_id
      }
      document.getElementById('loading-bg').style.display = 'block'

      this.$http.post('/clearance/infrastruktur', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.clearanceId,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const status = true
            this.infrastruktur_id = res.data.data.infrastruktur_id

            const promise1 = new Promise(resolve => {
              if (this.file1) {
                const formFile = new FormData()
                formFile.append('clearance_id', this.coreData.clearance_id)
                formFile.append('data_utama_id', this.clearanceId)
                formFile.append('infrastruktur_id', this.infrastruktur_id)
                formFile.append('kak_file', this.file1)

                this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      this.kak_uri = resp.data.data[0].document_uri
                      resolve(true)
                    } else {
                      const data = {
                        status: false,
                        msg: resp.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = resp.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    const data = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = error.response.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            const promise2 = new Promise(resolve => {
              if (this.file2.length !== 0) {
                for (let i = 0; i < this.file2.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('clearance_id', this.coreData.clearance_id)
                  formFile.append('data_utama_id', this.clearanceId)
                  formFile.append('infrastruktur_id', this.infrastruktur_id)
                  formFile.append('additional_files', this.file2[i])

                  this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                    params: {
                      token: localStorage.getItem('userToken'),
                    },
                  })
                    .then(resp => {
                      if (resp.data.status === 'success') {
                        this.additional_docs.push(resp.data.data[0])
                        resolve(true)
                      } else {
                        const data = {
                          status: false,
                          msg: resp.data.error,
                        }
                        document.getElementById('loading-bg').style.display = 'none'
                        this.errorMsg = resp.data.error
                        this.$emit('step-completed', data)
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      const data = {
                        status: false,
                        msg: error.response.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = error.response.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, promise2, status]).then(values => {
              if (values[0] && values[1] && values[2]) {
                const data = {
                  status: true,
                  msg: this.inf_data,
                }
                document.getElementById('loading-bg').style.display = 'none'
                data.msg.data_utama_id = this.clearanceId
                this.$refs.file_extra_doc.reset()
                this.file2 = []
                this.dataSaved = true
                this.errorMsg = ''
                this.$emit('step-completed', data)
              }
            })
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorMsg = `${res.data.message}: ${res.data.error}`
          }
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          document.getElementById('loading-bg').style.display = 'none'
          this.errorMsg = error.response.data.error
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
